import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const About = () => {
  useEffect(() => {
    // Title animation
    gsap.fromTo(
      '.about-title',
      { opacity: 0, scale: 0.5, y: -50 },
      { opacity: 1, scale: 1, y: 0, duration: 1.5, ease: 'elastic.out(1, 0.75)' }
    );

    // Content animation
    gsap.fromTo(
      '.about-content',
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1.5, stagger: 0.3, ease: 'power3.out' }
    );

    // Rotating paddles
    gsap.to('.paddle-image', {
      rotationY: 360,
      duration: 5,
      repeat: -1,
      ease: 'linear',
    });
  }, []);

  return (
    <div
      className="about-container"
      style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #03A9F4, #ffffff)', // Match hero section
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        textAlign: 'center',
        overflow: 'hidden',
      }}
    >
      {/* Title */}
      <h2
        className="about-title"
        style={{
          fontSize: '2.5em',
          color: '#ffffff',
          textShadow: '0 4px 10px rgba(3, 169, 244, 0.5)', // Glow effect
          marginBottom: '30px',
          fontWeight: 'bold',
        }}
      >
        Welcome to Pickleplex
      </h2>

      {/* Content Section */}
      <div
        className="about-content-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          maxWidth: '1000px',
        }}
      >
        {/* Rotating Paddle Image */}
        <img
          className="paddle-image"
          src="/aboutimage.png"
          alt="Rotating Paddle"
          style={{
            width: '150px',
            height: '150px',
            marginBottom: '20px',
          }}
        />

        {/* Text Section */}
        <div
          className="about-text"
          style={{
            fontSize: '1.2em',
            lineHeight: '1.8',
            color: '#ffffff',
            textShadow: '0 2px 5px rgba(0, 0, 0, 0.3)', // Subtle shadow for text
            textAlign: 'center',
            padding: '0 10px',
          }}
        >
          <p className="about-content">
            Hyderabad’s premier pickleball destination where fun meets fitness and community!
          </p>
          <p className="about-content">
            Nestled in the vibrant heart of Madhapur, we’re not just a sports facility—we’re a lifestyle experience designed for players of all ages and skill levels.
          </p>
          <p className="about-content">
            Whether you're a seasoned pro or a curious beginner, Pickleplex is where your game begins.
          </p>
          <p className="about-content">
            With top-notch courts, a lively atmosphere, and a passionate community, we redefine how Hyderabad unwinds.
          </p>
          <p className="about-content">
            From friendly matches to competitive leagues, we’ve got something exciting for everyone. Come for the game, stay for the energy, and leave as part of the Pickleplex family.
          </p>
          <p className="about-content">Ready to serve up some fun? Let’s play! 🌕✨</p>
        </div>

        {/* Rotating Paddle Image */}
        <img
          className="paddle-image"
          src="/aboutimage.png"
          alt="Rotating Paddle"
          style={{
            width: '150px',
            height: '150px',
            marginTop: '20px',
          }}
        />
      </div>
    </div>
  );
};

export default About;
