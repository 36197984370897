import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger, MotionPathPlugin } from 'gsap/all';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const InteractiveScroll = () => {
  const isMobile = window.innerWidth <= 768;

  const courtRefs = useRef([]); // References for the courts
  const textRefs = useRef([]); // References for the text sections
  const ballRef = useRef(null); // Reference for the pickleball image

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    // Motion path for pickleball with 3D effect
    gsap.to(ballRef.current, {

      motionPath: isMobile
      ? [
          { x: 0, y: 0, scale: 0.8 },
          { x: window.innerWidth * 0.4, y: window.innerHeight * 0.4, scale: 1 },
          { x: window.innerWidth * 0.8, y: window.innerHeight * 0.8, scale: 0.9 },
          { x: 0, y: window.innerHeight * 2.2, scale: 0.8 },
        ]
        : [
        { x: 0, y: 0, z: 0, scale: 1 },
        { x: window.innerWidth / 2, y: window.innerHeight / 2, z: 200, scale: 1.5 },
        {x: window.innerWidth, y: window.innerHeight, z: 0, scale: 1} ,
        { x: window.innerWidth / 2, y: window.innerHeight/2 + window.innerHeight, z: 200, scale: 1.5 },
        // { x: window.innerWidth / 2, y: window.innerHeight / 2, z: 200, scale: 1.5 },
        { x: 0, y: window.innerHeight *1.7, z: 0, scale: 1 },
      ],
      duration: 15,
      ease: 'power1.inOut',
      scrollTrigger: {
        trigger: '.interactive-container',
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
      },
    });

    // Darken courts and highlight text sections on scroll
    courtRefs.current.forEach((court, index) => {
      gsap.fromTo(
        court,
        { filter: 'brightness(1)' },
        {
          filter: 'brightness(0.8)',
          scrollTrigger: {
            trigger: court,
            start: 'top 80%',
            end: 'top 20%',
            scrub: true,
          },
        }
      );

      gsap.fromTo(
        textRefs.current[index],
        { filter: 'brightness(0.5)', opacity: 0 },
        {
          filter: 'brightness(2)',
          opacity: 1,
          scale: isMobile ? 1 :1.1,
          scrollTrigger: {
            trigger: textRefs.current[index],
            start: 'top 80%',
            end: 'top 20%',
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <div className="interactive-container" style={{ height: '200vh', position: 'relative' }}>
      {/* Pickleball Image */}
      <img
        ref={ballRef}
        src="/pickleball.png"
        alt="Pickleball"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: window.innerWidth <= 768 ? '100px' :'200px',
          height: window.innerWidth <= 768 ? '75px' : '150px',
          zIndex: 3,
          transformOrigin: 'center',
        }}
      />

      {[...Array(2)].map((_, index) => (
        <div
          key={index}
          ref={(el) => (courtRefs.current[index] = el)}
          style={{
            position: 'absolute',
            top: `${index * 100}vh`,
            width: '100%',
            height: '100vh',
            backgroundImage: `url(/path-to-court-image${index + 1}.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
          }}
        >
          <div
            ref={(el) => (textRefs.current[index] = el)}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: isMobile ? '95%' :'2000px',
              padding: isMobile ? '5px' :'20px',
              textAlign: 'center',
              zIndex: 2,
              background: 'rgba(0, 0, 0, 0.9)', // Black background
              borderRadius: '30px',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            {index === 0 && (
              <>
                <h2
                  style={{
                    fontSize: isMobile ? '1.2em' :'1.5em',
                    color: '#ffffff', // Neon green for headings
                    marginBottom: '10px',
                  }}
                >
                  What We Offer
                </h2>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: isMobile ? '10px' :'30px',
                    alignItems: 'center',
                  }}
                >
                  <div style={boxStyle(isMobile)}>
                    <h3 style={{ fontSize: isMobile ? '0.8em' :'1em', color: '#ffffff' }}>🏅 Five Premium Courts</h3>
                    <p style={{ fontSize: isMobile ? '0.7em' : '1em',color: '#ffffff' }}>
                    Elevate your game at Madhapur’s best pickleball facility. Our world-class courts are meticulously maintained to provide the ultimate playing experience, catering to both casual players and serious competitors.
                    </p>
                  </div>
                  <div style={boxStyle(isMobile)}>
                    <h3 style={{ fontSize: isMobile ? '0.8em' :'1em', color: '#ffffff' }}> Personalized Coaching</h3>
                    <p style={{ fontSize: isMobile ? '0.7em' : '1em', color: '#ffffff' }}>
                    Master the art of pickleball with expert coaches who tailor sessions for beginners, enthusiasts, and pros alike. Whether you’re just starting or perfecting your skills, our coaching sessions ensure you’re always improving.
                    </p>
                    <Link
              to="/coachings"
              style={{
                display: 'inline-block',
                width: '50px',
                height: '50px',
                border: '2px solid #FFD700',
                borderRadius: '50%',
                textAlign: 'center',
                lineHeight: '50px',
                color: '#FFD700',
                fontSize: '1.5em',
                textDecoration: 'none',
                position: 'relative',
                transition: 'transform 0.3s ease, background 0.3s ease',
                background: 'transparent',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'rotate(0deg)';
                e.target.style.background = '#FFD700';
                e.target.style.color = '#101010';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'rotate(90deg)';
                e.target.style.background = 'transparent';
                e.target.style.color = '#FFD700';
              }}
            >
              →
            </Link>
                  </div>
                  <div style={boxStyle(isMobile)}>
                    <h3 style={{ fontSize: isMobile ? '0.8em' :'1em', color: '#ffffff' }}>🏆 Exciting Events & Tournaments</h3>
                    <p style={{ fontSize: isMobile ? '0.7em' : '1em', color: '#ffffff' }}>
                    Feel the adrenaline of competition at PicklePlex. From fun community events to high-stakes tournaments, we bring Hyderabad’s pickleball enthusiasts together. (Coming Soon)
                    </p>
                    <Link
              to="/tournaments"
              style={{
                display: 'inline-block',
                width: '50px',
                height: '50px',
                border: '2px solid #FFD700',
                borderRadius: '50%',
                textAlign: 'center',
                lineHeight: '50px',
                color: '#FFD700',
                fontSize: '1.5em',
                textDecoration: 'none',
                position: 'relative',
                transition: 'transform 0.3s ease, background 0.3s ease',
                background: 'transparent',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'rotate(0deg)';
                e.target.style.background = '#FFD700';
                e.target.style.color = '#101010';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'rotate(90deg)';
                e.target.style.background = 'transparent';
                e.target.style.color = '#FFD700';
              }}
            >
              →
            </Link>
                  </div>
                </div>
              </>
            )}
            {index === 1 && (
              <>
                <h2
  style={{
    fontSize: isMobile ? '1.2em' :'1.5em',
    color: '#ffffff', // Neon green for headings
    marginBottom: '10px',
    textAlign: 'center',
  }}
>
  Why Pickleball is the Fastest-Growing Sport ?
</h2>
<div
  style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: isMobile ? '10px' : '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    background: 'rgba(3, 169, 244, 0.1)', // Subtle blue background
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(3, 169, 244, 0.6)', // Soft blue glow
    border: '2px solid rgba(3, 169, 244, 0.8)', // Blue border for a defined look
  }}
>
  <div style={boxStyle(isMobile)}>
    <h3 style={{ fontSize: isMobile ? '0.9em' :'1em', color: '#ffffff' }}>🇮🇳 Explosive Growth in India</h3>
    <p style={{ color: '#ffffff', fontSize: isMobile ? '0.8em' :'1em' }}>
      Pickleball is one of India’s fastest-growing sports, with over <strong>20,000 players </strong> 
      joining in the last five years.<br></br> <br></br>The game is gaining traction in metros like Hyderabad, Bangalore, 
      and Mumbai at an unprecedented rate!
    </p>
  </div>
  <div style={boxStyle(isMobile)}>
    <h3 style={{ fontSize: isMobile ? '0.9em' :'1em', color: '#ffffff' }}>🏆 Hyderabad's Rising Star</h3>
    <p style={{ color: '#ffffff', fontSize: isMobile ? '0.8em' :'1em' }}>
      Hyderabad is becoming a pickleball hub with the highest growth rate among Indian cities. <br></br> <br></br>
      With new courts like PicklePlex, we’re taking the game to the next level in the vibrant 
      Madhapur area.
    </p>
  </div>
  <div style={boxStyle(isMobile)}>
    <h3 style={{ fontSize: isMobile ? '0.9em' :'1em', color: '#ffffff' }}>📈 Surprising Stats</h3>
    <p style={{ color: '#ffffff', fontSize: isMobile ? '0.8em' :'1em' }}>
    Did you know pickleball is played by <strong>over 5 million people</strong> globally? <br/><br/>
      In India alone, the sport has grown by <strong>300%</strong> since 2020, making it a 
      sensation among both youth and working professionals.
    </p>
  </div>
</div>

              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const boxStyle = (isMobile) => ({
  flex: 1,
  padding: isMobile ? '10px' : '20px',
  background: 'rgba(3, 169, 244, 0.2)', // Subtle blue background
  borderRadius: '10px',
  textAlign: 'center',
  transition: 'all 0.3s ease',
  color: '#000', // Black text for better contrast
});


export default InteractiveScroll;