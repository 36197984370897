import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import CancellationRefundPolicy from './pages/CancellationRefundPolicy';
import Tournaments from './pages/Tournaments';
import ScrollToTop from './ScrollToTop';
import Coachings from './pages/Coachings';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div style={{ backgroundColor: '#101010', color: '#f5f5f5', minHeight: '100vh' }}>
        <Navbar />
        <div style={{ minHeight: '80vh', paddingTop: '10px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route path="/coachings" element={<Coachings />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
