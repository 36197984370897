import React from 'react';

const CancellationRefundPolicy = () => (
  <div
    style={{
      padding: '50px 20px',
      background: 'linear-gradient(135deg, #03A9F4, #ffffff)', // Blue to white gradient
      color: '#000', // Black text
      minHeight: '100vh',
      fontFamily: 'Roboto, sans-serif',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <h2
      style={{
        fontSize: '2.5em',
        color: '#ffffff', // White heading
        textShadow: '0 4px 8px rgba(3, 169, 244, 0.5)', // Blue glow
        marginBottom: '30px',
        textAlign: 'center',
      }}
    >
      Cancellation & Refund Policy
    </h2>

    <div
      style={{
        maxWidth: '800px',
        background: 'rgba(3, 169, 244, 0.1)', // Translucent blue background
        borderRadius: '10px',
        padding: '30px',
        boxShadow: '0 4px 10px rgba(3, 169, 244, 0.5)', // Soft blue shadow
        textAlign: 'justify',
        lineHeight: '1.8',
      }}
    >
      <h3
        style={{
          color: '#028cc9',
          fontSize: '1.5em',
          marginBottom: '15px',
          textAlign: 'left',
        }}
      >
        Cancellation Policy
      </h3>
      <p style={{ fontSize: '1.1em', marginBottom: '20px' }}>
        If cancellation is within 6 hours of the slot start time, no refund will be made. If
        cancellation is more than 6 hours prior, a <strong>95% amount paid</strong> will be
        refunded in the form of credits.
      </p>

      <h3
        style={{
          color: '#028cc9',
          fontSize: '1.5em',
          marginBottom: '15px',
          textAlign: 'left',
        }}
      >
        Rain Policy
      </h3>
      <p style={{ fontSize: '1.1em' }}>
        In case the court is not usable due to rain, the guest may email respective center emails
        with a snapshot of the booking and request a refund. The request needs to be made within
        <strong> 6 hours</strong> from the slot time. Center management will verify and, at its
        discretion, give chance equivalent to <strong>rescheduling of the court booking slot (subject to availability)</strong>.
      </p>
    </div>
  </div>
);

export default CancellationRefundPolicy;
