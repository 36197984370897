import React from 'react';

const HeroSection = () => {
  const isMobile = window.innerWidth <= 768; // Check if the device is mobile

  const handleBookCourtClick = () => {
    window.location.href = 'https://z34v4.app.goo.gl/iLwG'; // Redirect link
  };

  return (
    <div
      style={{
        height: '100vh',
        background: 'linear-gradient(to bottom, #ffffff, #f0f9ff)',
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        padding: isMobile ? '10px' : '0',
        fontFamily: `'San Francisco', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
      }}
    >
      {/* Subtle Background Glow */}
      <div
        style={{
          position: 'absolute',
          top: '-20%',
          left: '50%',
          width: isMobile ? '300px' : '600px',
          height: isMobile ? '300px' : '600px',
          background: 'radial-gradient(circle, rgba(3, 169, 244, 0.2), rgba(3, 169, 244, 0))',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      ></div>

      {/* Main Content */}
      <div
        style={{
          position: 'relative',
          zIndex: 2,
          padding: '0 20px',
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? '2.5em' : '4em',
            fontWeight: 600,
            marginBottom: '20px',
            letterSpacing: '0.5px',
            lineHeight: '1.2',
          }}
        >
          Welcome to PicklePlex
        </h1>
        <p
          style={{
            fontSize: isMobile ? '1em' : '1.5em',
            maxWidth: isMobile ? '90%' : '700px',
            lineHeight: '1.8',
            margin: '0 auto',
            color: '#333',
          }}
        >
          Discover world-class pickleball courts designed for all skill levels. Join us for an
          unforgettable experience of fun, fitness, and competition!
        </p>

        {/* Buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px',
            marginTop: '30px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Book a Court Now Button */}
          <button
            onClick={handleBookCourtClick}
            style={{
              padding: '15px 30px',
              fontSize: isMobile ? '1em' : '1.2em',
              color: '#ffffff',
              background: '#03A9F4',
              border: 'none',
              borderRadius: '50px',
              cursor: 'pointer',
              boxShadow: '0 8px 15px rgba(3, 169, 244, 0.3)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = 'scale(1.05)';
              e.target.style.boxShadow = '0 12px 25px rgba(3, 169, 244, 0.5)';
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = 'scale(1)';
              e.target.style.boxShadow = '0 8px 15px rgba(3, 169, 244, 0.3)';
            }}
          >
            Book a Court Now
          </button>

          {/* Join WhatsApp Community Button */}
          <a
            href="https://chat.whatsapp.com/Gfla9AyWGQ5Im9K7F07zxE"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              padding: '15px 30px',
              fontSize: isMobile ? '1em' : '1.2em',
              color: '#03A9F4',
              background: '#ffffff',
              border: '2px solid #03A9F4',
              borderRadius: '50px',
              textDecoration: 'none',
              cursor: 'pointer',
              boxShadow: '0 8px 15px rgba(255, 255, 255, 0.3)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = 'scale(1.05)';
              e.target.style.boxShadow = '0 12px 25px rgba(3, 169, 244, 0.5)';
              e.target.style.color = '#ffffff';
              e.target.style.background = '#03A9F4';
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = 'scale(1)';
              e.target.style.boxShadow = '0 8px 15px rgba(255, 255, 255, 0.3)';
              e.target.style.color = '#03A9F4';
              e.target.style.background = '#ffffff';
            }}
          >
            Join Hyderabad's Top Pickleball Community
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
