import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { FaWhatsapp, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => (
  <footer className="footer">
    {/* Branding and Location Info */}
    <div className="footer-section">
      <h3 className="footer-logo">Pickle Apex LLP</h3>
      <p className="footer-text">
        <strong>Location:</strong>{' '}
        <a
          href="https://maps.app.goo.gl/sY7coi3TfSqvQVQr7?g_st=iw"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          F94P+C7X, Meridian School Rd, Madhapur, Hyderabad
        </a>
      </p>
      <p className="footer-text">
        <strong>Phone:</strong> +91-8019922664
      </p>
      <p className="footer-text">
        <strong>Email:</strong>{' '}
        <a href="mailto:pickleplexhyd@gmail.com" className="footer-link">
          pickleplexhyd@gmail.com
        </a>
      </p>
    </div>

    {/* Quick Links Section */}
    <div className="footer-section">
      <h4 className="footer-heading">Quick Links</h4>
      <ul className="footer-list">
        <li>
          <Link to="/" className="footer-link">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="footer-link">
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" className="footer-link">
            Contact
          </Link>
        </li>
      </ul>
    </div>

    {/* Policies Section */}
    <div className="footer-section">
      <h4 className="footer-heading">Policies</h4>
      <ul className="footer-list">
        <li>
          <Link to="/cancellation-refund-policy" className="footer-link">
            Cancellation & Refund Policy
          </Link>
        </li>
        <li>
          <Link to="/terms-of-use" className="footer-link">
            Terms of Use
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
        </li>
      </ul>
    </div>

    {/* Follow Us Section */}
    <div className="footer-section">
      <h4 className="footer-heading">Follow Us</h4>
      <div className="social-icons">
        <a
          href="https://chat.whatsapp.com/Gfla9AyWGQ5Im9K7F07zxE"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          aria-label="WhatsApp"
        >
          <FaWhatsapp />
        </a>

        <a
          href="https://www.instagram.com/pickleplex_hyderabad/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          aria-label="Instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/in/pickle-plex-ba894b33a"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          aria-label="LinkedIn"
        >
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
